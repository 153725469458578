:root {
    --primary-color: #cc2935; /* Red */
    --secondary-color: #e1a300; /* Yellow */
    --background-color: #faf2e7; /* Beige */
    --text-color: #000000; /* Black */
}

body {
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/pictures/concrete-wall-textured-backgrounds-built-structure-concept.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
}
