.desktopview-button {
    padding: 1rem 2rem;
}

.desktopview-button:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    .desktopview-button {
        padding: 0;
    }
}
