.tv-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 2;
    pointer-events: none;
    user-select: none;
}

.game-zone {
    width: 65%;
    aspect-ratio: 4 / 3;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: hidden;
}

.time-left {
    position: absolute;
    font-size: 32px;
    left: 50%;
    transform: translateY(-25vh) translateX(-50%);
}

.info-container {
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    z-index: 5;
}

.page-find-food {
    min-height: 130vh;
}

.score {
    top: -35%;
    right: 5%;
    font-size: 2rem;
    color: white;
    z-index: 3;
}

.find-container {
    top: -35%;
    left: 5%;
    display: flex;
    align-items: center;
}

.find {
    font-size: 1.5rem;
    color: white;
    margin-right: 10px;
}

@media (max-width: 576px) {
    .time-left {
        transform: translateY(5vh) translateX(-50%);
        font-size: 16px;
    }

    .tv-frame {
        top: 80%;
        width: 100%;
    }

    .game-zone {
        width: 65%;
        top: 83%;
    }

    .score {
        font-size: 1rem !important;
        top: 10%;
        right: 15%;
    }

    .info-container {
        z-index: 3;
        transform: translateY(300%);
    }

    .find {
        font-size: 1rem !important;
    }

    .find-avatar {
        width: 24px;
        height: 24px;
    }

    .progress-circle {
        transform: scale(0.8);
    }

    .game-title {
        font-size: 1.2rem;
    }

    .page-find-food {
        min-height: auto;
    }
}
