.desktopview {
    transform: translateY(-50vh);
}

.title {
    font-size: 4rem;
    margin: 0;
    color: var(--primary-color);
    width: 100%;
}

.title-second {
    font-size: 4rem;
    margin: 0;
    color: var(--primary-color);
    width: 100%;
    display: none;
}

.logo {
    position: absolute;
    width: 8rem;
    height: 8rem;
    right: 0vw;
    margin: 0;
    z-index: -1;
}

@media (max-width: 768px) {
    .desktopview {
        transform: none;
        margin-top: 2rem;
    }

    .title {
        font-size: 4rem;
        display: none;
    }

    .title-second {
        display: block;
    }
}
