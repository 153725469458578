.event-map-scroll-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    scroll-snap-type: x mandatory;
    margin-bottom: 3rem;
}
.event-map-container {
    position: relative;
    min-width: 200%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transform: translateX(0);
}

.event-map-container.restaurant.out {
    animation: slideOutLeft 0.25s ease forwards;
}

.event-map-container.restaurant.in {
    animation: slideFromLeft 0.25s ease forwards;
}

.event-map-container.artisan.out {
    animation: slideOutRight 0.25s ease forwards;
}

.event-map-container.artisan.in {
    animation: slideFromRight 0.25s ease forwards;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.event-map {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.map-marker {
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.marker-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
}

.marker-image {
    position: absolute;
    width: clamp(1.5em, 5vw, 5vw);
    height: clamp(1.5em, 5vw, 5vw);
    z-index: 0;
}

.artisan-image {
    position: absolute;
    width: clamp(1.5em, 4vw, 4vw);
    height: clamp(1.5em, 4vw, 4vw);
    z-index: 0;
}

.marker-icon {
    position: relative;
    width: clamp(1.5em, 7vw, 7vw);
    height: auto;
    z-index: 1;
}

.artisan-icon {
    position: relative;
    width: clamp(1.5em, 5vw, 5vw);
    height: auto;
    z-index: 1;
    border-radius: 50%;
}

.marker-circle {
    position: absolute;
    width: clamp(1.5em, 4.5vw, 4.5vw);
    height: clamp(1.5em, 4.5vw, 4.5vw);
    background-color: #d90429;
    opacity: 0.7;
    border-radius: 50%;
    z-index: 0;
}

@media (min-width: 768px) {
    .event-map-scroll-container {
        width: 80%;
        margin: 0 auto 10rem;
        margin-bottom: 6rem;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        scroll-snap-type: x mandatory;
    }

    .event-map-container {
        min-width: 100%;
    }

    .marker-image-container {
        width: clamp(4.5em, 5vw, 5vw);
    }

    .marker-icon {
        width: clamp(3.5em, 4.5vw, 4.5vw);
        transition: transform 0.3s ease;
    }

    .marker-icon:hover {
        transform: scale(1.1);
    }

    .artisan-icon {
        width: clamp(2em, 4vw, 4vw);
        transition: transform 0.3s ease;
    }

    .artisan-icon:hover {
        transform: scale(1.1);
    }
}

.small-icon {
    width: clamp(1.5em, 3.5vw, 3.5vw);
}

.artisan-small-icon {
    width: clamp(1.5em, 3.5vw, 3.5vw);
}

.medium-icon {
    width: clamp(1.5em, 4.2vw, 4.2vw);
}

.lower-icon {
    transform: translateY(0.3vw);
    transition: transform 0.3s ease;
}

.lower-icon:hover {
    transform: translateY(0.3vw) scale(1.1);
}

.wc-icon {
    position: relative;
    width: clamp(1.5em, 7vw, 7vw);
    height: auto;
    z-index: 1;
}

.forum-local-icon {
    transform: translateX(0.1vw);
    transition: transform 0.3s ease;
}

.forum-local-icon:hover {
    transform: translateY(-0.4vw) translateX(0.1vw) scale(1.1);
}

.furniture-icon {
    position: relative;
    width: clamp(1.5em, 5.5vw, 5.5vw);
    height: clamp(1.5em, 5.5vw, 5.5vw);
    z-index: 1;
}

.sofa-icon {
    position: relative;
    width: clamp(1.5em, 4.8vw, 4.8vw);
    height: clamp(1.5em, 4.8vw, 4.8vw);
    z-index: 1;
}

.fauteuil-icon {
    position: relative;
    width: clamp(0.8em, 2.5vw, 2.5vw);
    height: auto;
    z-index: 1;
}

.bar-icon {
    position: relative;
    width: clamp(1.5em, 8vw, 8vw);
    height: auto;
    z-index: 1;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

.text-artisan {
    position: relative;
    width: clamp(1.5em, 8vw, 8vw);
    height: auto;
    z-index: 1;
}

.text-restaurant {
    position: relative;
    width: clamp(2em, 10vw, 10vw);
    height: auto;
    z-index: 1;
}

.reverse-arrow {
    position: relative;
    transform: rotate(180deg);
    width: clamp(2.5em, 6vw, 6vw);
    transition: transform 0s ease;
    z-index: 0;
}

.reverse-arrow:hover {
    transform: rotate(180deg) scale(1.1);
}

.arrow {
    position: relative;
    width: clamp(3.5em, 8vw, 8vw);
    transition: transform 0s ease;
    z-index: 0;
}

@media (max-width: 768px) {
    .marker-image {
        width: clamp(1.5em, 13vw, 13vw);
        height: clamp(1.5em, 13vw, 13vw);
    }

    .artisan-image {
        width: clamp(1.5em, 13vw, 13vw);
        height: clamp(1.5em, 13vw, 13vw);
    }

    .marker-icon {
        width: clamp(1.5em, 12vw, 12vw);
    }

    .artisan-icon {
        width: clamp(1.5em, 12vw, 12vw);
    }

    .marker-circle {
        width: clamp(1.5em, 13vw, 13vw);
        height: clamp(1.5em, 13vw, 13vw);
    }

    .small-icon {
        width: clamp(1.5em, 10vw, 10vw);
    }

    .artisan-small-icon {
        width: clamp(1.5em, 10vw, 10vw);
    }

    .medium-icon {
        width: clamp(1.5em, 11vw, 11vw);
    }

    .lower-icon {
        transform: translateY(1vw);
        transition: transform 0.3s ease;
    }

    .lower-icon:hover {
        transform: translateY(1vw) scale(1.1);
    }

    .wc-icon {
        position: relative;
        width: clamp(1.5em, 18vw, 18vw);
        height: auto;
        z-index: 1;
    }

    .forum-local-icon {
        transform: translateX(0.4vw);
        transition: transform 0.3s ease;
    }

    .forum-local-icon:hover {
        transform: translateY(-0.4vw) translateX(0.4vw) scale(1.1);
    }

    .furniture-icon {
        position: relative;
        width: clamp(1.5em, 15vw, 15vw);
        height: clamp(1.5em, 15vw, 15vw);
        z-index: 1;
    }

    .sofa-icon {
        position: relative;
        width: clamp(1.5em, 13.5vw, 13.5vw);
        height: clamp(1.5em, 13.5vw, 13.5vw);
        z-index: 1;
    }

    .fauteuil-icon {
        position: relative;
        width: clamp(0.8em, 7vw, 7vw);
        height: auto;
        z-index: 1;
    }

    .bar-icon {
        position: relative;
        width: clamp(1.5em, 22vw, 22vw);
        height: auto;
        z-index: 1;
    }

    .text-artisan {
        width: clamp(1.5em, 22vw, 22vw);
    }

    .text-restaurant {
        width: clamp(2em, 23vw, 23vw);
    }

    .reverse-arrow {
        width: clamp(2.5em, 18vw, 18vw);
    }

    .arrow {
        width: clamp(3.5em, 18vw, 18vw);
        transition: transform 0s ease;
    }
}
