.footer-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5vh;
    padding-left: 5rem;
    padding-right: 5rem;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateX(1.5rem);
}

.epitech-logo {
    width: 8rem;
    transform: translateX(0.5rem);
    padding-right: 1rem;
}

.epitech-logo:hover {
    cursor: pointer;
}

.men-image {
    width: 2rem;
    padding-left: 0.6rem;
}

.men-image:hover {
    cursor: pointer;
}

.x-brush {
    width: 1.5rem;
}

.separation-footer {
    z-index: -1;
    transform: rotate(90deg)translateX(-5vh)scaleX(0.2);
    position: absolute;
    height: 90vw;
    left: 45vw;
}

@media (max-width: 500px) {
    .footer-container {
        align-items: center;
        justify-content: center;
    }

    .button-home {
        display: none;
    }

    .separation-footer {
        position: absolute;
        height: 80vw;
        transform: rotate(90deg)translateX(-6.5vh)scaleX(1);
        left: 45vw;
    }

    .image-container {
        display: flex;
        position: absolute;
        align-items: center;
        transform: translateX(-0.5rem)translateY(-1rem);
    }

    .epitech-logo {
        width: 7rem;
        transform: translateX(0.5rem);
    }

    .men-image {
        margin-left: 0.2rem;
    }

    .x-brush {
        width: 4vw;
    }

    .button-legal-mention {
        align-self: center;
        position: absolute;
        transform: translateY(1rem);
    }
}

@media (max-height: 500px) {
    .separation-footer {
        transform: rotate(90deg)translateX(-7vh)scaleX(0.2);
    }
}
