.restorators-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.menu-separator {
    display: none;
}

.divider-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60vh;
    transform: scaleX(0.5);
    user-select: none;
}

.menu {
    width: 45%;
    margin-bottom: 2rem;
}

.salt {
    position: absolute;
    width: 20vw;
    transform: translateY(-50%);
    z-index: -1;
    overflow: hidden;
}

.cookie {
    position: absolute;
    width: 15vw;
    z-index: -1;
    overflow: hidden;
}

.drink {
    position: absolute;
    width: 12vw;
    z-index: -1;
    overflow: none;
}

@media (max-width: 768px) {
    .restorators-container {
        flex-direction: column;
        margin-left: 0;
        overflow: hidden;
    }

    .divider-menu {
        display: none;
    }

    .anton-regular {
        text-align: center;
    }

    .menu-separator {
        display: flex;
        z-index: -1;
        transform: rotate(-84deg)scaleX(1);
        resizeMode: "stretch";
        height: 70vw;
        left: 45vw;
        margin: -15vh;
    }

    .menu {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .salt {
        width: 35vw;
    }

    .cookie {
        width: 30vw;
    }

    .drink {
        width: 30vw;
    }
}
