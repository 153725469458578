.carousel-row {
    height: 30vh;
}

.carousel-image {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

@media (min-width: 768px) {
    .carousel-row {
        height: 100vh;
    }

    .carousel-image {
        height: 100vh;
    }
}
