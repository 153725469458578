.legal-mention-container {
    display: flex;
    flex-direction: column;
    margin: 2rem 10rem;
}

@media (max-width: 768px) {
    .legal-mention-container {
        margin: 4rem 2rem;
    }
}
