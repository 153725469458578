.sauce-clicker {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stain {
  position: absolute;

  width: 40px;
  height: 40px;
  opacity: 0.8;
}

.fast-progress .ant-progress-bg {
    transition: width 0.1s linear !important;
}

.mouth-stain {
    transition: opacity 0.5s ease;
  }

  .mouth {
    position: 'absolute';
    bottom: '40px';
    left: '54%';
    transform: 'translateX(-50%)';
    width: '225px';
    height: '80px';
    border-radius: '10px';
    overflow: 'hidden';
  }

  .sauce-clicker-container {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }

  .pot {
    width: 80%;
    height: 'auto';
  }

  .character {
    width: 100%;
    height: auto;
  }

  .sauce-stains {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
  .stain {
    animation: stain-animation 1s ease-in-out infinite;
  }
  .stain-mouth {
    background-size: cover;
    width: 12%;
    height: 10%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  @keyframes stain-animation {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
