.splash {
    position: absolute;
    width: 200px;
    height: 200px;
    mix-blend-mode: multiply;
}

.splash-top {
    top: 0;
    right: 0;
}

.splash-bottom {
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
}

.svg-container {
    display: none;
}

@media (min-width: 768px) {
    .splash {
        width: 300px;
        height: 300px;
    }

    .svg-container {
        display: block;
        position: absolute;
        top: 50%;
        right: -10%;
        transform: translateY(-50%);
        z-index: -1;
    }

    .svg-image {
        width: 400px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .pass-info {
        font-size: 1.3rem;
    }
}

@media (min-width: 1024px) {

    .pass-info {
        font-size: 1.7rem;
    }

    .svg-container {
        display: block;
        position: absolute;
        top: 50%;
        right: -10%;
        transform: translateY(-50%);
        z-index: -1;
    }

    .svg-image {
        width: 650px;
        height: auto;
    }
}
